export const PATH_REGISTER = "/register";
export const PATH_FORGOT_PASSWORD = "/forgot-password";
export const PATH_RESET_PASSWORD = "/reset-password";
export const PATH_FINISH_RESET_PASSWORD = "/users/password/edit";
export const PATH_LOGIN = "/login";
export const PATH_SETTINGS = "/settings";
export const PATH_STATISTICS = "/statistics";
export const PATH_FEED = "/";
export const PATH_ADD_TRANSACTION = "/transactions/add";
export const PATH_NOTIFICATIONS = "/notifications";
export const PATH_USER = "/user";
export const PATH_CHOOSE_TEAM = "/choose-team";
export const PATH_INVITE = "/invite";
export const PATH_CREATE_TEAM = "/create-team";
export const PATH_MANAGE_TEAM = "/manage-team";
